var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-container" },
    [
      _c("BackNav", {
        on: {
          click: function ($event) {
            return _vm.$router.go(-1)
          },
        },
      }),
      _c("h1", { staticClass: "text-lg font-bold" }, [
        _vm._v(
          " Customize " +
            _vm._s(_vm.cardType === "PHYSICAL" ? "physical" : "virtual") +
            " card "
        ),
      ]),
      _c("p", { staticClass: "mt-2 text-sm text-grey" }, [
        _vm._v(
          " Express your style with your credit card. Choose between Kong black, Zilla purple, Berry pink & Droid blue. "
        ),
      ]),
      _c("div", { staticClass: "w-full" }, [
        _c(
          "div",
          {
            staticClass: "card-image mx-auto mt-6 purple-bg",
            class: [_vm.cardBackgroundColor],
          },
          [
            _vm._m(0),
            _c("img", {
              staticClass: "wave",
              attrs: {
                src: "https://res.cloudinary.com/zillaafrica/image/upload/v1663580774/customer/Union_1_gkjcox.png",
                alt: "",
              },
            }),
          ]
        ),
        _c("div", { staticClass: "flex items-center customize--card" }, [
          _c(
            "div",
            { staticClass: "w-full mt-8" },
            [
              _c("p", { staticClass: "text-grey mb-2" }, [
                _vm._v("Enter card name"),
              ]),
              _c("Input", {
                attrs: { width: "w-full", placeholder: "E.g Shopping card" },
                model: {
                  value: _vm.nickname,
                  callback: function ($$v) {
                    _vm.nickname = $$v
                  },
                  expression: "nickname",
                },
              }),
              _c("p", { staticClass: "text-grey mt-8 text-sm text-center" }, [
                _vm._v("Choose your style"),
              ]),
              _c(
                "div",
                { staticClass: "mt-4 grid grid-cols-4 color-choice gap-4" },
                [
                  _c("div", {
                    staticClass: "black-icon box",
                    class: { selected: _vm.cardBackgroundColor === "black" },
                    on: {
                      click: function ($event) {
                        return _vm.handleCardColor("black")
                      },
                    },
                  }),
                  _c("div", {
                    staticClass: "purple-icon box",
                    class: { selected: _vm.cardBackgroundColor === "purple" },
                    on: {
                      click: function ($event) {
                        return _vm.handleCardColor("purple")
                      },
                    },
                  }),
                  _c("div", {
                    staticClass: "red-icon box",
                    class: { selected: _vm.cardBackgroundColor === "red" },
                    on: {
                      click: function ($event) {
                        return _vm.handleCardColor("red")
                      },
                    },
                  }),
                  _c("div", {
                    staticClass: "blue-icon box",
                    class: { selected: _vm.cardBackgroundColor === "blue" },
                    on: {
                      click: function ($event) {
                        return _vm.handleCardColor("blue")
                      },
                    },
                  }),
                ]
              ),
              _c("Button", {
                staticClass: "mt-10",
                attrs: { text: "Done", width: "w-full" },
                on: {
                  click: function ($event) {
                    return _vm.setCardColor()
                  },
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "logo-card flex justify-between items-center" },
      [
        _c("div", { staticClass: "logo" }, [
          _c("img", {
            attrs: {
              src: "https://res.cloudinary.com/zillaafrica/image/upload/v1663580773/customer/Group_13111_z0tw4h.svg",
              alt: "",
            },
          }),
        ]),
        _c("div", { staticClass: "verve" }, [
          _c("img", {
            attrs: {
              src: "https://res.cloudinary.com/zillaafrica/image/upload/v1663583200/customer/Group_kmnfzv.png",
              alt: "",
            },
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }