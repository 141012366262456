<template>
  <div class="card-container">
    <BackNav @click="$router.go(-1)" />
    <h1 class="text-lg font-bold">
      Customize {{ cardType === "PHYSICAL" ? "physical" : "virtual" }}
      card
    </h1>
    <p class="mt-2 text-sm text-grey">
      Express your style with your credit card. Choose between Kong black, Zilla
      purple, Berry pink & Droid blue.
    </p>
    <div class="w-full">
      <div
        class="card-image mx-auto mt-6 purple-bg"
        :class="[cardBackgroundColor]"
      >
        <div class="logo-card flex justify-between items-center">
          <div class="logo">
            <img
              src="https://res.cloudinary.com/zillaafrica/image/upload/v1663580773/customer/Group_13111_z0tw4h.svg"
              alt=""
            />
          </div>
          <div class="verve">
            <img
              src="https://res.cloudinary.com/zillaafrica/image/upload/v1663583200/customer/Group_kmnfzv.png"
              alt=""
            />
          </div>
        </div>
        <img
          class="wave"
          src="https://res.cloudinary.com/zillaafrica/image/upload/v1663580774/customer/Union_1_gkjcox.png"
          alt=""
        />
      </div>

      <div class="flex items-center customize--card">
        <div class="w-full mt-8">
          <p class="text-grey mb-2">Enter card name</p>
          <Input width="w-full" placeholder="E.g Shopping card" v-model="nickname" />
          <p class="text-grey mt-8 text-sm text-center">Choose your style</p>

          <div class="mt-4 grid grid-cols-4 color-choice gap-4">
            <div
              class="black-icon box"
              :class="{ selected: cardBackgroundColor === 'black' }"
              @click="handleCardColor('black')"
            ></div>
            <div
              class="purple-icon box"
              :class="{ selected: cardBackgroundColor === 'purple' }"
              @click="handleCardColor('purple')"
            ></div>
            <div
              class="red-icon box"
              :class="{ selected: cardBackgroundColor === 'red' }"
              @click="handleCardColor('red')"
            ></div>
            <div
              class="blue-icon box"
              :class="{ selected: cardBackgroundColor === 'blue' }"
              @click="handleCardColor('blue')"
            ></div>
          </div>
          <Button
            text="Done"
            width="w-full"
            class="mt-10"
            @click="setCardColor()"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button } from "@/UI/Button";
import { Input } from "@/UI/Input";
import { BackNav } from "@/UI/Navigation";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    Button,
    BackNav,
    Input,
  },
  data: () => ({
    cardBackgroundColor: "black",
    nickname: "",
  }),
  computed: {
    ...mapState({
      cardType: (state) => state?.card?.card?.type,
    }),
  },
  methods: {
    ...mapActions("card", ["updateCardCustomization"]),
    handleCardColor(color) {
      this.cardBackgroundColor = color;
    },
    setCardColor() {
      this.$router.push("/drawer/card/create-pin");
      const selectedColor = this.cardBackgroundColor.toUpperCase();
      this.updateCardCustomization({
        color: selectedColor,
        nickname: this.nickname,
      });
    },
  },
};
</script>
<style scoped>
.card-container {
  max-width: 36.563rem;
  margin: auto;
}
.card-image {
  position: relative;
  /* display: grid; */
  margin-top: 2.75rem;
  height: 14rem;
  max-width: 21rem;
  border-radius: 19px;
  transition: all 0.3s linear;
  box-shadow: 0px 11px 4px rgba(0, 0, 0, 0.03), 0px 6px 4px rgba(0, 0, 0, 0.09),
    0px 3px 3px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.17),
    0px 0px 0px rgba(0, 0, 0, 0.18);
  overflow: hidden;
  /* filter: drop-shadow(0px 77px 31px rgba(0, 0, 0, 0.03))
      drop-shadow(0px 43px 26px rgba(0, 0, 0, 0.11))
      drop-shadow(0px 19px 19px rgba(0, 0, 0, 0.19))
      drop-shadow(0px 5px 11px rgba(0, 0, 0, 0.21))
      drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.22)); */
}
.customize--card {
  max-width: 21rem;
  margin: auto;
}

.card-image .wave {
  position: absolute;
  bottom: 0;
}

.card-image .verve {
  width: 3.875rem;
  height: auto;
}
.card-image .logo {
  width: 6.875rem;
  height: auto;
}
.logo-card {
  position: absolute;
  bottom: 1.5rem;
  left: 1rem;
  right: 1rem;
  z-index: 10;
}
.color-choice {
  max-width: 14.26rem;
  margin: 1rem auto 0 auto;
}
.color-choice .box {
  height: 2rem;
  width: 2rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s linear;
}
.black {
  background-image: url("https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1663579293/customer/ZCard00_1_oz6i2c.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.purple {
  background-image: url("https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1663344397/customer/ZCard01_2_npv5dd.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.red {
  background-image: url("https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1663579250/customer/ZWall01_Color_corrected_2_eo7hht.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.blue {
  background-image: url("https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1663579244/customer/Card_D00_1_jjizgb.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.black-icon {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    radial-gradient(
      78.5% 146.7% at 24.68% 147.87%,
      rgba(98, 223, 223, 0.44) 0%,
      rgba(98, 223, 223, 0.02) 47.92%
    ),
    radial-gradient(
      87.6% 165.19% at 56.36% 140.03%,
      rgba(198, 0, 59, 0.69) 0%,
      rgba(198, 1, 60, 0) 51.56%
    ),
    radial-gradient(
      115.29% 217.4% at 4.31% 98.5%,
      #6956c8 0%,
      rgba(25, 12, 91, 0) 74.05%
    ),
    #000000;
}
.purple-icon {
  background: linear-gradient(
      111.74deg,
      #c6003b -198.45%,
      #6a30c3 22.71%,
      #63dfdf 191.46%
    ),
    #6a30c3;
}

.red-icon {
  background: #c6003b;
}
.blue-icon {
  background: #0ea2f5;
}
.selected {
  transform: scale(1.2);
  box-shadow: 0px 11px 4px rgba(0, 0, 0, 0.03), 0px 6px 4px rgba(0, 0, 0, 0.09),
    0px 3px 3px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.17),
    0px 0px 0px rgba(0, 0, 0, 0.18);
}
.purple-bg {
  background-color: #6a30c3;
}
</style>
