<template>
  <Customize />
</template>
<script>
  import Customize from "@/components/ZillaCards/Customize";
  export default {
    components: {
      Customize,
    },
  };
</script>
